import { createContext, createElement, useState, useContext } from 'react';
export const EnvChooserContext = createContext(null);
export const useEnvChooserContext = () => useContext(EnvChooserContext);
export const EnvChooserProvider = ({
  children
}) => {
  const [isEnvChooserVisible, setEnvChooserVisible] = useState(false);
  return createElement(EnvChooserContext.Provider, {
    value: {
      isEnvChooserVisible,
      setEnvChooserVisible
    }
  }, children);
};